// src/ServicesGrid.js
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './ServicesGrid.css';

const services = [
  {
    title: "Technology Leadership and Strategy Services",
    description: "Transform your tech strategy and operation",
    link: "services#technologyleadership"
  },
  {
    title: "Technology Evaluation & Roadmap Development",
    description: "Unlock Your Tech Potential",
    link: "services#technologyevaluation"
  },
  {
    title: "Building Right Size Solutions",
    description: "Transform challenges into opportunities for innovation and success",
    link: "services#buildingrightsizesolutions"
  },
  {
    title: "Product Engineering and Application Development",
    description: "Accelerate Innovation with Product Engineering and Application Development",
    link: "./services#productengineering"
  },
  {
    title: "Mobile Application Design and Development",
    description: "Empower Your Mobile Vision with Strategic Development",
    link: "./services#mobileappdevservices"
  },
  {
    title: "Cloud Strategy and Optimization",
    description: "Elevate Your Cloud Journey",
    link: "./services#cloudstrategyoptimization"
  },
  {
    title: "AI - ML Solutions",
    description: "Streamline your business operations effortlessly with our AI/ML solutions.",
    link: "./services#aisolutions"
  },
  {
    title: "DevOps and MLOps Services",
    description: "Accelerate and Innovate with DevOps and MLOps Consulting",
    link: "./services#devopsmlopsservices"
  },
  {
    title: "Cyber Security Services",
    description: "Secure Your Digital Future",
    link: "./services#cybersecurityservices"
  }
];

const ServicesGrid = () => {
  return (
    <section className="services-grid">
      <div className="grid-container">
        {services.map((service, index) => (
          <div className="grid-item" key={index}>
            <h3>{service.title}</h3>
            <HashLink to={service.link} className="read-more">READ MORE</HashLink>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesGrid;
