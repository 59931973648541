import './App.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Home from './components/home/Home';
import Services from './components/services/Services';
import Benefits from './components/benefits/Benefits';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <Router>
    <div className='app-page'>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/benefits" element={<Benefits/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />        
      </Routes>
      <Footer/>
    </div>

    </Router>
  );
}

export default App;