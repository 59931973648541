import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <h1>About</h1>
      
      <section className="vision">
        <h2>Our Vision</h2>
        <p>
          Our vision is to empower businesses of all sizes to achieve their full potential through technology. We believe in the transformative power of innovative solutions that can elevate businesses to greater heights. Our mission is to provide affordable technology solutions that enable startups and SMEs to compete with the best in the industry. We aim to be a global leader in technology consulting, providing unparalleled expertise and customized solutions to our clients.
        </p>
        <a href="mailto:hello@stemphortechlabs.com" className="cta-button">Let's talk</a>
      </section>

      <section className="team-story">
        <div className="team-image">
          <img src='team-story.jpg' alt="Stemphor Techlabs Team" />
        </div>
        <div className="team-content">
          <h2>Team Story</h2>
          <p>
            At Stemphor Techlabs, we're passionate about helping businesses succeed. Our team of seasoned technology experts brings a wealth of experience to help startups and SMEs overcome their biggest technology challenges. With a client-centric approach, we work collaboratively to deliver innovative solutions that meet their unique needs. We believe in building long-term partnerships with our clients based on trust, transparency, and excellence. At Stemphor Techlabs, we're not just a service provider, but your trusted partner in technology.
          </p>
        </div>
      </section>
    </div>
  );
}

export default About;