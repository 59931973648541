import React from 'react';
import './Services.css';


const Services = () => {
  const services = [
    {
      id: 'technologyleadership',
      title: "Technology Leadership",
      subTitle: "Transform your Technology Strategy and Operations",
      description: "Our advisory services focus on technology consulting, strategy, and governance, tailored to meet your business objectives. It encompasses strategic planning, technology roadmap development, innovation management, providing vision on technology investments and priorities, building competitive advantage through the technology stack, and digital transformation guidance, all tailored to your specific business objectives. We assist in developing, overseeing, and advising on software budget distribution, optimizing resource allocation, streamlining the decision making process, planning actionable technology strategies, and establishing key performance indicators (KPIs) to evaluate technology efficiency and return on investment. Our approach is comprehensive, focusing on optimizing every facet of your operations for maximum efficiency. Concentrating on strategic planning and flexibility, we turn visions into measurable outcomes.",
      image: 'tech-leadership.jpg'
    },
    {
      id: 'productengineering',
      title: "Product Engineering and Application Development",
      subTitle: "Accelerate Innovation with Product Engineering and Application Development",
      description: "Our product engineering consulting services are tailored to empower startups and SMEs to bring their visions to market with speed and precision. We fabricate technology strategies fit for your business needs and budget that take your company to new heights. Specializing in the end-to-end development process, we combine innovative engineering solutions with efficient delivery methodologies to transform your ideas into reality. From conceptual design to product launch, we ensure your products are not just completed, but are market-ready and aligned with your business goals. Partner with us to leverage cutting-edge technologies, optimize product development cycles and user experience, and achieve a faster time-to-market.",
      image: 'product-engineering.jpg'
    },
    {
      id: 'technologyevaluation',
      title: "Technology Evaluation & Roadmap development",
      subTitle: "Unlock Your Technology Potential",
      description: "Our ‘Technology Evaluation and Roadmap Development’ consulting services is designed to propel you forward by making informed technology decisions. We dive deep into your business needs to identify and analyse the most effective technology solutions that align with your objectives, ensuring scalability, innovation, and market competitiveness. It involves evaluating emerging technologies, the identification of comprehensive software audits to craft a strategic technology roadmap, system integrations, better risk management and identification of potential challenges, build vs buy decisions, helping and evaluating the right vendors, and digital transformation strategies to optimize operations and drive growth to build a sustainable and tech-driven future. We help formulate a clear and actionable technology roadmap and effectively communicate technology plans to stakeholders, considering future proofing technology investments, and adaptability to technology evolution and market changes.",
      image: 'tech-evaluation.jpg'
    },
    {
      id: 'mobileappdevservices',
      title: "Mobile Application Design and Development Services",
      subTitle: "Empower Your Mobile Vision with Strategic Development",
      description: "Our ‘Mobile Application Development Strategy’ consulting services are designed to elevate startups and SMEs in the fast-paced mobile market. We understand the critical role mobile apps play in connecting with your audience and scaling your business. Our expertise lies in crafting a comprehensive strategy that encompasses market analysis, user experience design, technology selection, and deployment approaches tailored to your unique needs and goals. By partnering with us, you gain access to insights that ensure your mobile application not only meets but exceeds user expectations, driving engagement and business growth. Let’s collaborate to transform your mobile app concept into a reality that captivates your target audience and secures your place in the digital future.",
      image: 'mobile-app-dev-services.jpg'
    },
    {
      id: 'buildingrightsizesolutions',
      title: "Building Right Size Solutions",
      subTitle: "Transform Challenges into Opportunities for Innovation and Success",
      description: "This service is designed for startups and SMEs poised for growth but facing technological or operational hurdles. It encompasses identifying, evaluating, and recommending the most effective solutions with in-depth technology landscape analysis to meet your unique business challenges like SaaS based products, mobile applications, etc. Whether it’s streamlining processes, enhancing technology infrastructure, regulatory compliances, industry standards, or adopting new digital tools, we guide you through a tailored selection process to find the right fit for your business’s needs to unlock your business’s potential, drive efficiency, secure a competitive advantage, and partner with you in technology implementation and execution.",
      image: 'right-sized-solutions.jpg'
    },
    {
      id: 'cloudstrategyoptimization',
      title: "Cloud Strategy and Optimization",
      subTitle: "Elevate Your Cloud Journey",
      description: "Our ‘Cloud Strategy and Optimization’ consulting services are tailored to harness the full potential of cloud computing. With a focus on creating a bespoke cloud strategy that aligns with your business goals, ensuring scalability, cost-efficiency, and enhanced performance. From selecting the right cloud platforms to optimizing your existing cloud infrastructure, we guide you through every step, empowering you to innovate, reduce IT overheads, and achieve operational excellence by driving growth and sustainability in the digital era.",
      image: 'cloud-strategy-optimization.jpg'
    },
    {
      id: 'aisolutions',
      title: "AI Solutions",
      subTitle: "Unlock the Power of Data and Elevate Journey with Data-Driven Insights and Analytics",
      description: "Provides tailored AI/ML strategies that drive innovation, automate processes, and unlock new opportunities for growth. Provide access to expert insights and cutting-edge solutions that ensure you stay ahead by optimising operations, enhancing customer experiences, and making data-driven decisions that lead to sustainable success. From ideation and data preparation to model development, building feedback loops, synthesising the data, continuous training, deployment, and continuous improvement, our comprehensive approach ensures you leverage machine learning technologies effectively and efficiently. We help in harnessing the potential of machine learning to propel your business into the future with co-innovation, co-hack and co-development.",
      image: 'machine-learning-solutions .jpg'
    },
    {
      id: 'devopsmlopsservices',
      title: "DevOps and MLOps Services",
      subTitle: "Accelerate and Innovate with DevOps and MLOps Consulting",
      description: "Our ‘DevOps and MLOps’ consulting services are tailored to streamline your development, operations, and machine learning operations, fostering a culture of CI/CD and CI/CD/CT. It encompasses change and release management, optimizing workflows, automating testing and deployment, infrastructure as a code, application performance monitoring, and enhancing collaboration across teams to ensure faster time-to-market and improved product quality. We help build reliable and secured applications with proactive monitoring and performance management, better automation and optimization of development processes, feedback loops to enable continuous training for AI/ML applications, and incremental training solutions for the AI/ML stack. Our approach not only focuses on the technical aspects of DevOps and MLOps but also on their strategic implementation to align with your business objectives, enabling you to leverage the full potential of automation and machine learning in your projects. Partner with us to transform your development and operational capabilities, reduce inefficiencies, and drive sustainable growth through cutting-edge practices.",
      image: 'devops-mlops-services.jpg'
    },
    {
      id: 'cybersecurityservices',
      title: "CyberSecurity Services​",
      subTitle: "Secure Your Digital Future ",
      description: "Specializing in cybersecurity for Startups and SMEs, our consultancy provides robust protection for your business in the digital age. We understand the unique challenges and threats you face, and offer customized cybersecurity solutions designed to safeguard your assets, data, and online presence. With our expertise, you can confidently navigate the complexities of cyber threats, ensuring business continuity and securing customer trust. Partner with us to fortify your defenses, comply with industry standards, and build a resilient cybersecurity posture that supports your growth and innovation. Your security is our priority, let’s protect your business together.",
      image: 'cyber-security-services​ .jpg'
    },
    {
      id: 'technologisthiring',
      title: "Technologist Hiring​",
      subTitle: "Bringing in Right Talent",
      description: "We help organizations with full-time technologist hiring during the scale or evolution of business by conducting screening procedures, rigorous technical evaluations, and behavior-based interviews. We help craft targeted talent acquisition strategies to meet your business needs, along with an onboarding process to integrate technologists seamlessly into an organization.",
      image: 'technologist-hiring​ .jpg'
    },
  ];

  return (
    <div className="services-page">
      <h1>Our Services</h1>
      <p>At Stemphor Techlabs, we offer a comprehensive range of services to help businesses leverage the power of technology and drive innovation. Our expert team is dedicated to delivering cutting-edge solutions tailored to your specific needs.</p>
      
      {services.map((service, index) => (
        <div key={index} id={service.id} className="service-section">
          {index % 2 === 0 ? (
            <>
              <div className="service-image">
                <img src={service.image} alt={service.title} />
              </div>
              <div className="service-content">
                <h2>{service.title}</h2>
                <h3>{service.subTitle}</h3>
                <p>{service.description}</p>
              </div>
            </>
          ) : (
            <>
              <div className="service-content">
                <h2>{service.title}</h2>
                <h3>{service.subTitle}</h3>
                <p>{service.description}</p>
              </div>
              <div className="service-image">
                <img src={service.image} alt={service.title} />
              </div>
            </>
          )}
        </div>
      ))}

    </div>
  );
}

export default Services;