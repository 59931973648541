// src/Header.js
import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <a href="/" className="header-logo-link">
        <div className="logo-container">
          <img src='stemphor-logo.png' className="header-logo" alt="Stemphor Logo"/>
          <h4>
            Stemphor<br/>Techlabs
          </h4>
        </div>
      </a>
      <button className="menu-toggle" onClick={toggleMenu}>
        <img src='phone-menu.png' className="header-phone-menu" alt="Menu"/>
      </button>
      <nav className={isMenuOpen ? 'nav open' : 'nav'}>
        <ul>
          <li><a href="/services">Services</a></li>
          <li><a href="/benefits">Benefits</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
