import React from 'react';
import './Benefits.css';

const Benefits = () => {
  const benefits = [
    {
      title: "Cost Effective",
      description: "Optimize your cost without hiring a full-time technology executive. Save on infrastructure and hiring costs with our flexible, customized services.",
      icon: "💰"
    },
    {
      title: "Flexibility",
      description: "Access a variety of specialists on-demand to achieve your company objectives. Use our services whenever and wherever you need us.",
      icon: "🔄"
    },
    {
      title: "Decision Making",
      description: "Make objective, data-driven decisions based on metrics and benchmarking. We help match R&D to business priorities for better execution.",
      icon: "📊"
    },
    {
      title: "Dedicated Expertise",
      description: "Gain deep industry insights and technical prowess to navigate challenges with confidence. Stay ahead with emerging technologies and market trends.",
      icon: "🧠"
    },
    {
      title: "Innovation",
      description: "Accelerate your digital transformation with our technical expertise and creative strategies, tailored to your end users and business needs.",
      icon: "💡"
    },
    {
      title: "Automation",
      description: "Streamline processes and increase productivity with cutting-edge automation solutions, maximizing your competitive advantage.",
      icon: "🤖"
    },
    {
      title: "High Availability and Scalability",
      description: "Access specialized services precisely when and where you need them, with VTOs synchronized to your time zone and communication preferences.",
      icon: "🌐"
    },
    {
      title: "Support",
      description: "Get expert guidance on technical issues, debugging, and complex problem-solving. We help with build vs buy decisions and technology stack optimization.",
      icon: "🛠️"
    }
  ];

  return (
    <div className="benefits-page">
      <div className="benefits-header">
        <h1>Benefits of On Demand Technology Services and VTO</h1>
        <p>Discover how our services can transform your business and drive innovation</p>
      </div>
      <div className="benefits-grid">
        {benefits.map((benefit, index) => (
          <div key={index} className="benefit-card">
            <div className="benefit-icon">{benefit.icon}</div>
            <h2>{benefit.title}</h2>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Benefits;