// src/Home.js
import React from 'react';
import './Home.css';
import ServicesGrid from './ServicesGrid';
import Faq from './Faq';

const Home = () => {
  return (
    <section id="home" className="home">
      <h1>On Demand Technology Services and Virtual Technology Officer (VTO)</h1>
      <p>
        At Stemphor Techlabs, we provide technology leadership, engineering solutions, and cloud optimization services to SMEs and startups worldwide. With a focus on Better, Cheaper, and Faster approaches, we help you succeed in the competitive digital landscape through innovation and automation.
      </p>
      <p>
        Together, we strive to deliver business impact and drive operational excellence.
      </p>
      <p>
      <img src='meeting-handshake.jpg' alt="Home"/>
      </p>
      
      <h1>Why VTO (Virtual CTO)?</h1>
      <div className="why-vto">
        <p>
          A VTO, a.k.a. Virtual CTO is a seasoned technology leader and executive who works on a part-time or project basis by providing technical expertise, guidance, coaching, mentoring, and training with practical experience in the different facets of technology implementation and initiatives for an organization.        
        </p>
        <p>
          Our endeavour is to provide cost effective and affordable services to startups and SMEs to make them successful without the need for a dedicated CTO.  Our team has access to a broad network of technology leaders and industry professionals to ensure long term success. We believe in partnerships whereby organizations can focus on growing their businesses while we take care of their technology blueprint and needs in a reliable manner.
        </p>
      </div>

      <h3 className="banner-strip">All the Benefits of having a CTO, without all the costs</h3>

      <div className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <div className="features">
          <div className="feature">
            <h3>Excellence</h3>
            <p>We craft experiences beyond your expectations with a passion for excellence and meticulous attention.</p>
          </div>
          <div className="feature">
            <h3>Professionalism</h3>
            <p>We guarantee a hassle-free and seamless experience for you by providing top-notch services and expertly managing demands in an affordable way.</p>
          </div>
          <div className="feature">
            <h3>Expertise</h3>
            <p>Our in-depth understanding and knowledge of the technology enables us to design a service that is specifically suited to your company’s requirements.</p>
          </div>
          <div className="feature">
            <h3>Integrity</h3>
            <p>Transparency, honesty, and sincerity are values we uphold in all of our endeavours, from teamwork to objective decision-making.</p>
          </div>
        </div>        
      </div>
      
      <h1>Services we provide</h1>
      <ServicesGrid />

      <div className="how-it-works">
        <h1>How does it work?</h1>
        <ol className="steps">
          <li>
            Conversations to gain insight into the issues and conduct analysis of them through in-depth meetings with pertinent SMEs to quantify and qualify the pain points.
          </li>
          <li>
            Providing a readout and supporting materials through in-depth research outlining the advantages and disadvantages of the suggested technological solution(s), taking into account other possibilities for qualification and alignment.
          </li>
          <li>
            Sign off on the scope, cost, duration, goal settings and desired outcomes.
          </li>
          <li>
            Periodic meetings to share progress and feedback by ensuring all stakeholders are highly-aligned and things are moving in the right direction.
          </li>
          <li>
            Reporting to various audiences using data points to convey improvement outcomes and goal attainment backed by analytics.
          </li>
        </ol>
      </div>        

      <Faq />
    </section>    
  );
};

export default Home;
