import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Prepare the template parameters
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      from_phone: formData.phone_number,
      message: formData.message,
    };

    // Send email using EmailJS
    emailjs.send(
      'service_uu5t4j5',
      'template_4edzpaj',
      templateParams,
      'ghwoSZ6STVZa3ChMZ'
    )
    .then((response) => {
      console.log('Email sent successfully:', response);
      // Reset form after successful submission
      setFormData({ name: '', email: '', phone_number: '', message: '' });
      setIsEmailSent(true);
    })
    .catch((error) => {
      console.error('Error sending email:', error);
    });
  };

  return (
    <div className="contact-page">
      <h1>Get in touch with us</h1>
      <div className="contact-info">
        <p>
          At Stemphor, we're committed to helping you find the right solutions for your technology needs. Whether you're a startup or a small to medium enterprise, we offer a range of services to help you succeed. From technology leadership to mobile application design and development, and cloud strategy and optimization, we're here to help you achieve your goals. Contact us today to learn more about our services and how we can help you transform your challenges into opportunities for innovation and success.
        </p>
      </div>
      <div className="contact-form-container">
        <form onSubmit={handleSubmit} className="contact-form">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="phone_number"
            placeholder="Phone"
            value={formData.phone_number}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button 
            type="submit" 
            className="submit-button" 
            disabled={isEmailSent}
          >
            {isEmailSent ? 'Message Sent' : 'Send Message'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;