// src/Faq.js
import React, { useState } from 'react';
import './Faq.css';

const faqData = [
  {
    question: "How much do VTO (Virtual Technology Officer) and on-demand services cost?",
    answer: <div>
                <p>The pricing of our services vary depending on factors such as scope, duration, expected roles and responsibilities, and the level of engagement required. We offer diverse business models tailored to support Startups and SMEs economically, aiding their scalable growth effectively.</p>
                <p>Our goal is to help our clients succeed affordably. Please don’t hesitate to arrange a call with us.</p>
            </div>
  },
  {
    question: "What will be the duration of VTO services with the company?",
    answer: <div>
                <p>The length of time our consulting VTOs are engaged is determined by factors such as the project or product scope, the nature of services needed, and specific requirements. We commit ourselves fully to companies for the necessary duration (like T&M or fixed cost or on-demand), offering our assistance and expertise for as long as it’s needed. </p>
                <p>We can adjust our involvement according to your company’s needs, offering a customized approach to achieve your objectives and desired outcomes. It’s important to note that we provide genuinely on-demand VTO services.</p>
            </div>
  },
  {
    question: "Why should I hire a VTO for my project?",
    answer: <div>
                <p>You need VTO if you lack technology experience or tech founder, struggle with technology adoption and growth, don’t require a full-time CTO, face budget constraints, have an inexperienced team, technology not bringing in operational excellence, or encounter challenges in scaling, then our Virtual Technology Officer (VTO) service is the solution.</p>
                <p>Our VTOs understand technology depth and breadth with practical experience of solving complex and hard problems in efficient ways. VTO possess the know-how to assist businesses in making difficult technological decisions, including awareness of current trends and their development. This allows companies to focus on business while we take care of the technology part to achieve the desired state in an affordable and timely manner.</p>
            </div>
  },
  {
    question: "Do you provide VTO as a service for Startups?",
    answer: <div>
                <p>Indeed, it aligns perfectly with our mission to facilitate the success of Startups in a cost-effective and efficient manner.</p>
                <p>Startups need experts who can provide strategic direction and guidance, assist in selecting the right technology stack with future scalability in mind, and optimize both solutions and technological resources to achieve their business objectives in a competitive environment. Through our VTO service, Startups can access specialized technology experts tailored to their needs.</p>
            </div>
  },
  {
    question: "Does the company need to arrange a set-up for the VTO?",
    answer: <div>
                <p>Employing a VTO has several advantages, one of which is that you won’t need to spend money on a full setup because the VTO works remotely and needs no setup fees.</p>
                <p>We sign an NDA with the company, allowing them to give VTO access to the necessary records, information, and resources so they may effectively work and communicate with different teams from any location, accommodating the company’s time zone preferences.</p>
            </div>
  },
  {
    question: "How much time does it take to deploy a VTO for a company?",
    answer: "The timeline depends on the scope and nature of the services required, including their duration. We will arrange a meeting to discuss the specifics and needs in order to schedule the expert’s engagement in two to four weeks. After initial discussions, we will craft a contract to provide relevant and meaningful services to your company."
  },
  {
    question: "Will VTO represent the company in strategic and technical discussions with investors?",
    answer: "Absolutely. VTO works with the short- and long-term goals of the company and decides on investments that can help reach business objectives through automation and innovative software solutions."
  },
  {
    question: "How can I avail VTO services?",
    answer: "Kindly fill out the form by clicking on the contact and providing the details to have pertinent conversations and get it started. Don’t hesitate to give us a shout!"
  }
];

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq">
      <h2>Frequently Asked Questions</h2>
      {faqData.map((item, index) => (
        <div
          className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          key={index}
          onClick={() => toggleFaq(index)}
        >
          <div className="faq-question">
            <span>{item.question}</span>
            <span className="arrow">
              {activeIndex === index ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 15L12 9L6 15" stroke="#000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 9L12 15L18 9" stroke="#000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              )}
            </span>
          </div>
          <div className="faq-answer">{item.answer}</div>
        </div>
      ))}
    </section>
  );
};

export default Faq;
